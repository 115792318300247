<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="学生:">
          <el-input 
						v-model.trim="searchForm.studentname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="录入人:">
          <el-input 
						v-model.trim="searchForm.realname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="异动类型:">
          <el-select 
						v-model="searchForm.transactiontype" 
						@visible-change="visibleType"
						@keyup.enter.native="seach"
						ref="projecttyperef"
						clearable>
            <el-option
              v-for="item in changeTypes"
              :key="item.value"
              :value="item.label"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="教学点:">
          <el-input 
						v-model.trim="searchForm.schoolorgname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="学生" prop="studentname" align="center" width="120px">
        <template slot-scope="scope" align="center">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.studentname
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="异动类型" prop="transactiontype" align="center" width="130px">
      </el-table-column>
      <el-table-column
        label="原院校"
        prop="collegename1"
        align="center"
        width="150"
      />
      <el-table-column
        label="原专业"
        prop="majorname1"
        align="center"
        width="150"
      />
      <el-table-column
        label="转后院校"
        prop="collegename2"
        align="center"
        width="150"
      />
      <el-table-column
        label="转后专业"
        prop="majorname2"
        align="center"
        width="150"
      />
      <el-table-column label="录入人" prop="realname" align="center" width="120px"/>
      <el-table-column label="教学点" prop="schoolorgname" align="center" width="120px"/>
      <el-table-column label="办理状态" prop="status" align="center">
        <template slot-scope="scope">
          {{ status[scope.row.status] }}
        </template>
      </el-table-column>
      <el-table-column label="办理成功" prop="isflag" align="center"/>
			<el-table-column label="函授站" prop="guakaoschoolorgname" align="center" width="120px"/>
			<el-table-column label="上课点" prop="classaddressname" align="center" width="120px"/>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope" align="center">
          <el-button @click="downloadFile(scope.row)" type="primary" size="mini"
            >下载凭证图片</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			:close-on-click-modal='false'
			:width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 60%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>{{ dialogTitle }}</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item label="学生姓名:">
          {{ dynamicValidateForm.studentname }}
        </el-form-item>
        <el-form-item label="年级:">
          {{ dynamicValidateForm.grade }}
        </el-form-item>
        <el-form-item label="层次:">
          {{ dynamicValidateForm.levelval }}
        </el-form-item>
        <el-form-item label="办理状态:">
          {{ status[dynamicValidateForm.status] }}
        </el-form-item>
        <el-form-item label="原院校:">
          {{ dynamicValidateForm.collegename1 }}
        </el-form-item>
        <el-form-item label="原专业:">
          {{ dynamicValidateForm.majorname1 }}
        </el-form-item>
        <el-form-item label="转后院校:">
          {{ dynamicValidateForm.collegename2 }}
        </el-form-item>
        <el-form-item label="转后专业:">
          {{ dynamicValidateForm.majorname2 }}
        </el-form-item>
        <el-form-item label="录入人:">
          {{ dynamicValidateForm.realname }}
        </el-form-item>
        <el-form-item label="录入时间:">
          {{
            dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD HH:mm:ss")
          }}
        </el-form-item>
        <el-form-item label="原学习形式:">
          {{ dynamicValidateForm.studytypeval1 }}
        </el-form-item>
        <el-form-item label="转后学习形式:">
          {{ dynamicValidateForm.studytypeval2 }}
        </el-form-item>
        <el-form-item label="分公司:">
          {{ dynamicValidateForm.companyorgname }}
        </el-form-item>
        <el-form-item label="教学点:">
          {{ dynamicValidateForm.schoolorgname }}
        </el-form-item>
        <el-form-item label="异动类型:">
          {{ changeType[dynamicValidateForm.transactiontype] }}
        </el-form-item>
        <el-form-item label="是否办理成功:">
          {{ dynamicValidateForm.isflag }}
        </el-form-item>
        <el-form-item label="图片url:">
					<template>
						<el-image
						:src="$url.upload + dynamicValidateForm.picurl" 
						style="width: 70px; height: 70px;"
						/>
					</template>
        </el-form-item>
        <el-form-item label="备注:">
          {{ dynamicValidateForm.remark }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--修改-->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      width="35%"
      :title="dialogTitle"
			:close-on-click-modal='false'
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="姓名" prop="studentname">
          <el-input
            v-model="dynamicValidateForm.studentname"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="异动类型:" prop="transactiontype">
          <el-select v-model="dynamicValidateForm.transactiontype" clearable>
            <el-option
              v-for="item in changeTypes"
              :key="item.value"
              :value="item.label"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原院校" prop="collegename1">
          <el-input
            v-model="dynamicValidateForm.collegename1"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="原专业" prop="majorname1">
          <el-input
            v-model="dynamicValidateForm.majorname1"
            disabled
          ></el-input>
        </el-form-item>
        <div
          v-if="
            dynamicValidateForm.transactiontype === '转学转专业' ||
            dynamicValidateForm.transactiontype === '转学' ||
            dynamicValidateForm.transactiontype === '转专业'
          "
        >
          <el-form-item label="转后院校:" prop="collegename2">
            <el-input v-model="dynamicValidateForm.collegename2" disabled />
            <el-button
							type="primary"
						 @click="onSelectData(
								 collegemajor_dialogresultall,
								'showCollegeModal'
							)"
						 >选择</el-button>
          </el-form-item>
          <el-form-item label="转后专业" prop="majorname2">
            <el-input
              v-model="dynamicValidateForm.majorname2"
              disabled
            ></el-input>
          </el-form-item>
        </div>
        <div v-if="dynamicValidateForm.transactiontype === '转学习形式'">
          <el-form-item label="转学习形式:" prop="studytypeval2">
            <el-select v-model="dynamicValidateForm.studytypeval2" clearable>
              <el-option
                v-for="item in studytypeval"
                :key="item.key"
                :value="item.val"
                :label="item.val"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item prop="picurl" label="异动凭证（图片）：">
          <el-input v-model="dynamicValidateForm.picurl" disabled />
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :before-upload="beforeUpload"
            :http-request="uploadFile"
            :show-file-list="false"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model.trim="dynamicValidateForm.remark"
            rows="6"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择院校 表格 -->
    <el-dialog
      class="btn-2b5a95"
      width="80%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择院校："
      :visible.sync="showCollegeModal"
			v-if="showCollegeModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmCollege">确定</el-button>
      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="专业名称:" prop="name">
              <el-input 
								v-model.trim="selectSearchForm.name" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
						<el-form-item label="院校名称:" prop="collegename">
						  <el-input 
								v-model.trim="selectSearchForm.collegename" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								clearable />
						</el-form-item>
						<el-form-item label="专业层次:" prop="majorlevelval">
						  <el-input 
								v-model.trim="selectSearchForm.majorlevelval"
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								clearable />
						</el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
			<el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="专业名称" prop="name" align="center" />
        <el-table-column label="院校名称" prop="collegename" align="center" />
        <el-table-column label="专业层次" prop="majorlevelval" align="center" />
        <el-table-column
          label="是否招生标志"
          prop="enableflag"
          align="center"
        >
				<template slot-scope="scope">
					{{ scope.row.enableflag == 1 ? "是" : "否" }}
				</template>
				</el-table-column>
      </el-table>
     <el-pagination
       background
       @current-change="
         (val, data) =>
           handleSelectChange(val, Object.assign({}, selectSearchForm))
       "
       :current-page="selectPageInfo.pageindex"
       :page-size="selectPageInfo.pagesize"
       :page-sizes="[10, 20, 30, 40, 50]"
       layout="total, prev, pager, next, jumper"
       :total="selectTable.totalcount"
     ></el-pagination>
    </el-dialog>

    <!--办理结束-->
    <el-dialog
      class="btn-2b5a95"
      width="40%"
			v-dialogDrag
      :title="dialogTitle"
      :visible.sync="showApprove2Modal"
			v-if="showApprove2Modal"
			:close-on-click-modal='false'
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="20%"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="是否办理成功:" prop="isflag">
          <el-select v-model="dynamicValidateForm.isflag" clearable>
            <el-option value="是"></el-option>
            <el-option value="否"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            v-model.trim="dynamicValidateForm.remark"
						clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveApprove2('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  transaction_list,
  transaction_input,
  transaction_save,
  transaction_delete,
  transaction_approve2,
  transaction_managesuccess,
} from "@/request/api/allChildrenProject";
import {
  _fileupload_save,
  _filedownload_save,
  _collegemajor_dialogresultall,
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import { _changeTypes, _changeType } from "@/assets/js/filedValueFlag";
export default {
  mixins: [part],
  name: "transaction",
  components: {},
  props: {
		 collegemajor_dialogresultall: {
			 default: () => _collegemajor_dialogresultall,
		 },
	},
  data() {
    return {
			selectPageInfo: {
			  pageindex: 1,
			  pagesize: 10,
			},
			selectSearchForm: {},
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      status: {
        0: "办理中",
        1: "办理结束",
      },
      studytypeval: [], // 学习形式
      showAddModal: false, // 添加
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        studentname: [{ trigger: "blur", message: "必填字段", required: true }],
        transactiontype: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        collegename1: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        studytypeval2: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        majorname1: [{ trigger: "blur", message: "必填字段", required: true }],
        collegename2: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        majorname2: [{ trigger: "blur", message: "必填字段", required: true }],
        picurl: [{ trigger: "blur", message: "必填字段", required: true }],
        isflag: [{ trigger: "blur", message: "必填字段", required: true }],
        remark: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      fds: new FormData(),

      collegeTable: {}, //院校表格
      showCollegeModal: false,

      changeTypes: _changeTypes,
      changeType: _changeType,

      showApprove2Modal: false, //办理结束
    };
  },
  created() {
    this.getDataList();
    this.dic_combox({
      typecode: "studytype",
      list: "studytypeval",
      that: this,
    });
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["dic_combox"]),
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.projecttyperef.blur();
			}
		},
		reset() {
			this.searchForm = {
				studentname: null,
				realname: null,
				transactiontype: null,
				schoolorgname: null
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		
		selectQuery() {
			this.selectSearchForm = {
				name: null,
				collegename: null,
				majorlevelval: null
				
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, null);
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: transaction_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //切换课程表格页
    handleCourseChange(val) {
      this.pageinfo.pageindex = val;
      this.onSelectCollege();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看详情路由页 api
    btnShowDetail(row) {
      myRequest({
        url: transaction_input,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.vtransaction;
        }
      });
    },

    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //办理结束 click
    btnSuccess() {
      selectCheck(this.multipleSelection, "办理结束", false, () => {
        this.dialogTitle = "办理结束";
        this.approve2();
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //上传文件前 before
    beforeUpload(file) {
      this.fds.append("upfile", file); // 传文件
    },
    //上传文件 upload
    uploadFile() {
      myRequest({
        method: "post",
        url: _fileupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm.picurl = res.data.url;
        }
      });
    },
    //确认院校 click
    onConfirmCollege() {
      selectCheck(this.multipleSelection, "确认院校", false, () => {
        this.$set(this.dynamicValidateForm,'majorname2',this.multipleSelection[0].name)
        this.$set(this.dynamicValidateForm,'collegename2',this.multipleSelection[0].collegename)
        this.dynamicValidateForm.majorid2 = this.multipleSelection[0].id;
        this.dynamicValidateForm.collegeid2 = this.multipleSelection[0].collegeid;
        this.showCollegeModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: transaction_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.vtransaction;
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.deleteflag; //deleteflag 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: transaction_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showAddModal",
            }
          );
        }
      });
    },
		//选项表格 api
		onSelectData(url, modal, data) {
			this.selectTableUrl = url;
			myRequest({
				url: this.selectTableUrl,
				data: Object.assign(data || {}, this.selectPageInfo),
			}).then((res) => {
				if (res.data.code === "200") {
					this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
					this.selectTable = res.data.data;
					this[modal] = true;
				}
			});
		},
		//选项表格 - 搜索 click
		onSelectSearchClick(url, modal, data) {
			this.selectPageInfo.pageindex = 1;
			this.onSelectData(url, modal, data);
		},
		//关闭选项框 click
		onCloseSelect() {
			this.selectPageInfo.pageindex = 1;
		},
		//切换选项表格页
		handleSelectChange(val, data) {
		  this.selectPageInfo.pageindex = val;
		  this.onSelectData(this.selectTableUrl, null, data);
			this.onCloseSelect();
		},
    //选择院校  api
   onSelectCollege() {
      myRequest(
			{
        url: collegemajor_dialogresultall,
				data: this.selectPageInfo
      },
			{
			  isRefTableList: true,
			  that: this,
			}
			).then((res) => {
        if (res.data.code === "200") {
          this.multipleSelection = [];
          this.collegeTable = res.data.data;
          this.showCollegeModal = true;
        }
      });
    },
    //下载 click
    downloadFile(row) {
      location.href =
        this.$url.Freemarker + _filedownload_save + "?fileurl=" + row.picurl;
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: transaction_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //办理结束路由页 api
    approve2() {
      myRequest({
        url: transaction_approve2,
        data: {
          id: this.multipleSelection[0].id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showApprove2Modal = true;
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.vtransaction;
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.deleteflag; //deleteflag 该字段不用传
        }
      });
    },
    //保存办理结束 api
    saveApprove2(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: transaction_managesuccess,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showApprove2Modal",
            }
          );
        }
      });
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>


<style  lang="scss">
</style>